@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@font-face {
  font-family: "Montserrat Light";
  src: url(/static/media/Montserrat-Light.02afb26f.ttf);
}
@font-face {
  font-family: "Montserrat";
  src: url(/static/media/Montserrat-Regular.3cd78665.ttf);
}
@font-face {
  font-family: "Montserrat Medium";
  src: url(/static/media/Montserrat-Medium.e2d60bc4.ttf);
}
@font-face {
  font-family: "Montserrat SemiBold";
  src: url(/static/media/Montserrat-SemiBold.fa8441f3.ttf);
}
@font-face {
  font-family: "Montserrat Bold";
  src: url(/static/media/Montserrat-Bold.079ca05d.ttf);
}
@font-face {
  font-family: "Montserrat ExtraBold";
  src: url(/static/media/Montserrat-ExtraBold.1944c8d1.ttf);
}

:root {
  --primary: #f8f4f0;
  --secondary: #1a1a1a;
  --shopPrimary: #231f20;
  --shopSecondary: #f8f5f2;
  --productImage: #f2eeeb;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

html {
  overflow-y: scroll;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Cart_backdrop__1v-c_ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000dd;
  z-index: 9;
}

.Cart_cart__274c0 {
  position: fixed;
  top: 0;
  right: 0;
  width: 33.33333%;
  height: 100%;
  background: #f8f5f2;
  z-index: 10;
  overflow-y: scroll;
}

.Cart_cart__274c0::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.Cart_container__2Z30n {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 70%;
  height: 100%;
  margin: 0 auto;
  cursor: default;
}

.Cart_close__2bjHj {
  position: absolute;
  top: 6.5%;
  right: 14%;
  padding: 2.5%;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
}

.Cart_svgClose__Y__eD {
  width: 19px;
  height: 19px;
}

.Cart_cartContentWrapper__2UCom {
  width: 100%;
}

.Cart_title__39Smw {
  padding: 113px 0 0;
  font-size: 32px;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  line-height: 32px;
}

.Cart_cartList__2zoxc {
  padding: 16px 0;
}

.Cart_totalPrice__2enKS {
  margin: 25px 0 0;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  font-size: 0.9rem;
}

.Cart_subtitle__28fGn {
  margin: 40px 0 0;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  font-size: 12px;
}

.Cart_svgCart__1XbqN {
  fill: #e0ddda;
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 75px);
  width: 150px;
  height: 150px;
}

@media (max-width: 780px) {
  .Cart_cart__274c0 {
    width: 100%;
  }
}

.SlideUpButton_link__NWiK5 {
  display: block;
  text-decoration: none;
  margin: 25px 0 50px;
  width: 100%;
  height: 75px;
  line-height: 75px;
  border: 1px solid #b8b4b2;
  position: relative;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  overflow: hidden;
}

.SlideUpButton_linkText__1F1IQ {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-decoration: none;
  color: #231f20;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  font-size: 0.9rem;
  position: relative;
  z-index: 1;
  transition: all 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.SlideUpButton_link__NWiK5:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #231f20;
  position: absolute;
  transition: all 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  top: 75px;
  left: 0;
}

.SlideUpButton_link__NWiK5:hover:before {
  top: 0;
}

.SlideUpButton_link__NWiK5:hover .SlideUpButton_linkText__1F1IQ {
  color: var(--primary);
}

.CartItem_cartContent__3WVLv {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 32px 0 0;
}

.CartItem_cartImage__35-qG {
  width: 128px;
  height: 128px;
  object-fit: contain;
  background: #efeae5;
}

.CartItem_cartDetails__3ivsA {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 10px 0;
}

.CartItem_cartName__3w3nT,
.CartItem_cartPrice__1lIp2 {
  font-size: 0.9rem;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
}

.CartItem_cartCategory__2Up8z,
.CartItem_cartName__3w3nT {
  padding-left: 10px;
}

.CartItem_cartCategory__2Up8z {
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  font-size: 1.3rem;
}

.CartItem_cartName__3w3nT {
  margin: 15px 0 0;
}

.CartItem_cartPrice__1lIp2 {
  padding: 6px 0;
}

.CartItem_quantityController__jsn_P {
  margin: 15px 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.CartItem_quantityControlButton__2xZ3C {
  width: 42px;
  height: 42px;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
}

.CartItem_quantityDisplay__1kOEB {
  width: 64px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  border: 1px solid #e6dcd2;
  background: none;
  font-size: 0.9rem;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
}

.CartItem_quantityDisplay__1kOEB:focus {
  outline: none;
}

.CartItem_quantityDisplay__1kOEB::-webkit-outer-spin-button,
.CartItem_quantityDisplay__1kOEB::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.CartItem_quantityDisplay__1kOEB[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 780px) {
  .CartItem_cartImage__35-qG {
    width: 50px;
    height: 50px;
  }

  .CartItem_cartCategory__2Up8z {
    font-size: 0.9rem;
  }

  .CartItem_cartName__3w3nT,
  .CartItem_cartPrice__1lIp2 {
    font-size: 0.8rem;
  }
}

.Header_container__1WJ3f {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  top: 12%;
  left: 5%;
  position: absolute;
  z-index: 5;
  cursor: default;
}

.Header_logo__2x5aF,
.Header_shop__3hkLb,
.Header_btn__1il0R,
.Header_cartLengthBlack__1MFNi {
  color: var(--primary);
}

.Header_logoBlack__3KW_D,
.Header_shopBlack__ZfmDd,
.Header_btnBlack__38v9y,
.Header_cartLength__vzqSJ {
  color: var(--shopPrimary);
}

.Header_logo__2x5aF {
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Montserrat Medium";
  font-weight: 500;
  letter-spacing: 5px;
}

.Header_logoBlack__3KW_D {
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Montserrat Medium";
  font-weight: 500;
  letter-spacing: 5px;
}

.Header_nav__1MTW2 {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Header_shop__3hkLb {
  text-decoration: none;
  font-size: 0.9rem;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
}

.Header_shopBlack__ZfmDd {
  text-decoration: none;
  font-size: 0.9rem;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
}

.Header_btn__1il0R {
  background: none;
  border: none;
  font-size: 0.9rem;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header_btnBlack__38v9y {
  background: none;
  border: none;
  font-size: 0.9rem;
  cursor: pointer;
  outline: none;
}

.Header_svgSearch__344G1 {
  width: 26px;
  height: 26px;
}

.Header_cartBtnContainer__1BYaE {
  position: relative;
  display: flex;
  align-items: center;
}

.Header_cartLength__vzqSJ {
  position: absolute;
  width: 29px;
  height: 29px;
  left: calc(-100% - 7px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: var(--shopSecondary);
  font-size: 0.9rem;
  font-family: "Montserrat Bold";
  font-weight: 700;
}

.Header_cartLengthBlack__1MFNi {
  position: absolute;
  width: 29px;
  height: 29px;
  left: calc(-100% - 7px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: var(--shopPrimary);
  font-size: 0.9rem;
  font-family: "Montserrat Bold";
  font-weight: 700;
}

.Header_svgArrow__gPcx1 {
  position: absolute;
  left: calc(100% - 2px);
  color: var(--shopSecondary);
}

.Header_svgArrowBlack__cc1TU {
  position: absolute;
  left: calc(100% - 2px);
  color: var(--shopPrimary);
}

.Header_svgCart__1iSzX {
  width: 29px;
  height: 29px;
}

@media (max-width: 780px) {
  .Header_container__1WJ3f {
    flex-direction: column;
    height: 100px;
    top: 5%;
  }

  .Header_nav__1MTW2 {
    width: 100%;
    justify-content: space-around;
  }
}

.Search_backdrop__3jW_i {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000dd;
  z-index: 9;
}

.Search_search__1uULo {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background: none;
  z-index: 10;
}

.Search_container__3v0nq {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--shopSecondary);
}

.Search_close__1CZif {
  position: absolute;
  top: 13%;
  right: 22.25%;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  color: var(--shopSecondary);
}

.Search_svgClose__2Z6Mf {
  width: 19px;
  height: 19px;
}

.Search_form__N_wEz {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Search_svgSearch__10vDN {
  width: 2.708vw;
  height: 2.708vw;
}

.Search_textField__1TkYI {
  color: var(--shopSecondary);
  background: none;
  border: none;
  outline: none;
  font-size: 4.427vw;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  width: 40%;
  padding: 0 0 0 1.38889vw;
  cursor: default;
}

.Search_textField__1TkYI::-webkit-input-placeholder {
  color: var(--shopSecondary);
}

.Search_textField__1TkYI:-ms-input-placeholder {
  color: var(--shopSecondary);
}

.Search_textField__1TkYI::placeholder {
  color: var(--shopSecondary);
}

.Search_btn__oHTt4 {
  width: 5.2vw;
  height: 2.6vw;
  background: none;
  outline: none;
  border: none;
  font-family: "Montserrat Medium";
  font-weight: 500;
  font-size: 0.75vw;
  border: 2px solid #363233;
  color: var(--primary);
  transition: opacity 0.4s ease-out;
  opacity: 0;
}

.Search_line__5afnS {
  width: 50%;
  height: 0.26vw;
  background: var(--shopSecondary);
}

@media (max-width: 780px) {
  .Search_svgSearch__10vDN {
    width: 2.7vw;
    height: 2.7vw;
  }

  .Search_textField__1TkYI {
    font-size: 4.427vw;
  }

  .Search_btn__oHTt4 {
    width: 5.2vw;
    height: 2.6vw;
    font-size: 0.75vw;
  }

  .Search_line__5afnS {
    height: 0.26vw;
  }
}

.HomePage_container__3tvUr {
  color: var(--primary);
  background: var(--secondary);
  cursor: default;
}

.HomePage_s1__3JI_y {
  background-size: cover;
  background-position: center center;
  min-height: 100vh;

  padding: 160px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.HomePage_s1Title__1z8up {
  font-size: 48px;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
}

.HomePage_s1Subtitle__2DLxi {
  font-size: 24px;
  margin: 18px 0;
  font-family: "Montserrat";
  font-weight: 400;
}

.HomePage_s1Link__tno64 {
  position: relative;
  border: none;
  background: orange;
  color: var(--secondary);
  padding: 16px 40px;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9rem;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
}

.HomePage_s1Link__tno64:hover:before {
  -webkit-transform: translate3d(3px, 3px, 0);
          transform: translate3d(3px, 3px, 0);
}

.HomePage_s1Link__tno64:before {
  border: 1px solid orange;
  box-sizing: border-box;
  content: " ";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
  width: 100%;
}

@media (max-width: 780px) {
  .HomePage_s1Title__1z8up {
    font-size: 36px;
  }
  
  .HomePage_s1Subtitle__2DLxi {
    font-size: 16px;
  }
}

.ProductPage_container__1wj1h {
  min-height: 100vh;
  background: var(--shopSecondary);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--shopPrimary);
  cursor: default;
}

.ProductPage_main__3ak4o {
  flex: 1 1;
  min-height: 100vh;
  padding: 0 25px 0 75px;
  margin-right: 25px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.ProductPage_cursor__24dYA {
  position: absolute;
  display: none;
  width: 80px;
  height: 80px;
  background: #231f20;
  z-index: 15;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  pointer-events: none;
  transition: top 0.1s ease-out, left 0.1s ease-out;
  color: var(--shopSecondary);
  justify-content: center;
  align-items: center;
}

.ProductPage_interactionArea__Dwp0j {
  position: absolute;
  width: 90%;
  height: 60%;
}

.ProductPage_leftClickArea__2QVfs {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
}

.ProductPage_rightClickArea__18axC {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  z-index: 1;
}

.ProductPage_leftClickArea__2QVfs:hover {
  cursor: none;
}

.ProductPage_rightClickArea__18axC:hover {
  cursor: none;
}

.ProductPage_bgStyle__ljpcC {
  background: #efece9;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
}

.ProductPage_productName__2s2eO {
  z-index: 2;
  position: absolute;
  top: 30%;
  left: 10%;
  width: 20%;
  font-size: 64px;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  line-height: 64px;
  pointer-events: none;
}

.ProductPage_productImage__1kx0L {
  z-index: 1;
  -webkit-user-drag: none;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.1s;
}

.ProductPage_galleryCounter__3qOcj {
  z-index: 1;
  position: absolute;
  bottom: 5.5%;
  right: 7.5%;
  font-family: "Montserrat Medium";
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 0.9rem;
  pointer-events: none;
}

.ProductPage_galleryCurrentIndex__2KOOz {
  font-family: "Montserrat Bold";
  font-weight: 700;
}

.ProductPage_side__2xwcY {
  width: 20vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 50px 0 25px;
}

.ProductPage_squares__3zfpn {
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 30vh 0 0;
}

.ProductPage_square__DGyGH {
  text-align: center;
  width: 45px;
  line-height: 45px;
  text-transform: uppercase;
  font-family: "Montserrat Bold";
  font-weight: 700;
  font-size: 0.9rem;
}

.ProductPage_productPrice__38IfS {
  font-size: 1.3rem;
  font-family: "Montserrat Medium";
  font-weight: 500;
  letter-spacing: 2px;
  margin: 10vh 0 0;
}

.ProductPage_buyButtons__2Zt-6 {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20vh 0 0;
}

.ProductPage_addToBag__fdP_t {
  background: #c9c1b1;
  border: none;
  outline: none;
  color: var(--shopPrimary);
  font-size: 0.9rem;
  cursor: pointer;
  text-transform: uppercase;
  height: 75px;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  letter-spacing: 3px;
  position: relative;
}

.ProductPage_addToBag__fdP_t div {
  height: 1rem;
  overflow: hidden;
  position: relative;
}

.ProductPage_addToBag__fdP_t div :nth-child(1) {
  position: absolute;
  top: calc(50% - (0.9rem / 2));
  left: 0%;
  width: 100%;
  height: 0.9rem;
  overflow: hidden;
  transition: top 0.2s ease-in;
}

.ProductPage_addToBag__fdP_t div :nth-child(2) {
  position: absolute;
  top: 100%;
  left: 0%;
  width: 100%;
  height: 0.9rem;
  overflow: hidden;
  transition: top 0.2s ease-in;
}

.ProductPage_addToBag__fdP_t:hover div :nth-child(1) {
  top: calc(0% - 0.9rem);
}

.ProductPage_addToBag__fdP_t:hover div :nth-child(2) {
  top: calc(50% - (0.9rem / 2));
}

.ProductPage_buyNow__3x4SQ {
  margin: 20px 0 0;
  background: #89734c;
  border: none;
  outline: none;
  color: var(--shopSecondary);
  font-size: 0.9rem;
  cursor: pointer;
  text-transform: uppercase;
  height: 75px;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  letter-spacing: 3px;
}

@media (max-width: 780px) {
  .ProductPage_container__1wj1h {
    flex-direction: column;
  }

  .ProductPage_main__3ak4o {
    flex: 1 1;
    min-height: 100vh;
    padding: 150px 5vw 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .ProductPage_productName__2s2eO {
    z-index: 2;
    position: relative;
    top: 0;
    left: 0;
    width: 80%;
    font-size: 32px;
    font-family: "Montserrat SemiBold";
    font-weight: 600;
    line-height: 32px;
    pointer-events: none;
  }

  .ProductPage_productImage__1kx0L {
    max-width: 80vw;
    height: auto;
    z-index: 1;
    -webkit-user-drag: none;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.1s;
  }

  .ProductPage_side__2xwcY {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 50px 0;
  }

  .ProductPage_squares__3zfpn {
    margin: 50px 0 0;
  }

  .ProductPage_productPrice__38IfS {
    margin: 50px 0 0;
  }

  .ProductPage_buyButtons__2Zt-6 {
    margin: 50px 0 100px;
  }
}

.ShopPage_container__1HMOC {
  background: var(--shopSecondary);
  min-height: 100vh;
  cursor: default;
  color: var(--shopPrimary);
}

.ShopPage_wrapper__2WFt7 {
  width: 80%;
  margin: 0 auto;
  padding: 27vh 0 10vh;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.ShopPage_side__263CZ {
  flex: 1 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 50px;
}

.ShopPage_sideTitleShop__lenb5 {
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  font-size: 0.9rem;
}

.ShopPage_sideTitle__XpaMh {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  font-size: 32px;
  padding: 5px 0 0;
  line-height: 1em;
}

.ShopPage_sideTitle__XpaMh span {
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  font-size: 32px;
}

.ShopPage_categories__2k3Ai {
  width: 100%;
  padding: 120px 0 0;
}

.ShopPage_category__2f_gx {
  position: relative;
  z-index: 0;
  cursor: pointer;
  list-style: none;
  font-size: 1.3rem;
  line-height: 2.6rem;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
}

.ShopPage_category__2f_gx:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  background: #e3dfdc;
  transition: -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  bottom: 8px;
  height: 10px;
}

.ShopPage_category__2f_gx:hover:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.ShopPage_categoryLink__3PHYk {
  color: var(--shopPrimary);
  text-decoration: none;
  width: 100%;
}

.ShopPage_main__1UF1Y {
  flex: 4 1;
  display: flex;
  justify-content: center;
}

.ShopPage_productList__w_Mg7 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.ShopPage_product__25Vz_ {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 25px 50px 0;
  /* width: 350px; */
  width: max(18.229vw, 300px);
}

.ShopPage_product__25Vz_:hover .ShopPage_frontImage__2RT6q {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  opacity: 0;
}

.ShopPage_product__25Vz_:hover .ShopPage_backImage__9cz9b {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  z-index: 0;
  opacity: 1;
}

.ShopPage_productLink__jSrQo {
  text-decoration: none;
  color: var(--shopPrimary);
}

.ShopPage_productImage__ywDPv {
  position: relative;
  width: max(18.229vw, 300px);
  height: max(18.229vw, 300px);
  overflow: hidden;
}

.ShopPage_frontImage__2RT6q {
  position: absolute;
  top: 0;
  left: 0;
  width: max(18.229vw, 300px);
  height: max(18.229vw, 300px);
  object-fit: contain;
  -webkit-transform: scale(1);
          transform: scale(1);
  background: var(--productImage);
  transition: all 0.2s cubic-bezier(0.61, 1, 0.88, 1),
    -webkit-transform 0.4s cubic-bezier(0.61, 1, 0.88, 1);
  transition: all 0.2s cubic-bezier(0.61, 1, 0.88, 1),
    transform 0.4s cubic-bezier(0.61, 1, 0.88, 1);
  transition: all 0.2s cubic-bezier(0.61, 1, 0.88, 1),
    transform 0.4s cubic-bezier(0.61, 1, 0.88, 1),
    -webkit-transform 0.4s cubic-bezier(0.61, 1, 0.88, 1);
  opacity: 1;
}

.ShopPage_backImage__9cz9b {
  position: absolute;
  top: 0;
  left: 0;
  width: max(18.229vw, 300px);
  height: max(18.229vw, 300px);
  object-fit: cover;
  -webkit-transform: scale(1);
          transform: scale(1);
  background: var(--productImage);
  transition: all 0.2s cubic-bezier(0.61, 1, 0.88, 1),
    -webkit-transform 0.4s cubic-bezier(0.61, 1, 0.88, 1);
  transition: all 0.2s cubic-bezier(0.61, 1, 0.88, 1),
    transform 0.4s cubic-bezier(0.61, 1, 0.88, 1);
  transition: all 0.2s cubic-bezier(0.61, 1, 0.88, 1),
    transform 0.4s cubic-bezier(0.61, 1, 0.88, 1),
    -webkit-transform 0.4s cubic-bezier(0.61, 1, 0.88, 1);
  z-index: -1;
  opacity: 0;
}

.ShopPage_productName__8Dmmv {
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  padding: 20px 0 0;
  font-size: 1.3rem;
}

.ShopPage_productPrice__339fM {
  font-family: "Montserrat Medium";
  font-weight: 500;
  padding: 13.333px 0 0;
  font-size: 0.9rem;
}

@media (max-width: 780px) {
  .ShopPage_wrapper__2WFt7 {
    flex-direction: column;
    padding: 200px 0 0;
    width: 100%;
  }

  .ShopPage_side__263CZ {
    width: 80%;
    margin: 0 auto;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
  }

  .ShopPage_categories__2k3Ai {
    padding: 50px 0 50px;
  }

  .ShopPage_productList__w_Mg7 {
    padding: 0 20px 0;
    justify-content: center;
  }

  .ShopPage_product__25Vz_ {
    margin: 0 10px 25px;
    width: max(40vw, 150px);
  }

  .ShopPage_productImage__ywDPv {
    position: relative;
    width: max(40vw, 150px);
    height: max(40vw, 150px);
    overflow: hidden;
  }

  .ShopPage_frontImage__2RT6q {
    position: absolute;
    top: 0;
    left: 0;
    width: max(40vw, 150px);
    height: max(40vw, 150px);
    object-fit: contain;
    -webkit-transform: scale(1);
            transform: scale(1);
    background: var(--productImage);
    transition: all 0.2s cubic-bezier(0.61, 1, 0.88, 1),
      -webkit-transform 0.4s cubic-bezier(0.61, 1, 0.88, 1);
    transition: all 0.2s cubic-bezier(0.61, 1, 0.88, 1),
      transform 0.4s cubic-bezier(0.61, 1, 0.88, 1);
    transition: all 0.2s cubic-bezier(0.61, 1, 0.88, 1),
      transform 0.4s cubic-bezier(0.61, 1, 0.88, 1),
      -webkit-transform 0.4s cubic-bezier(0.61, 1, 0.88, 1);
    opacity: 1;
  }

  .ShopPage_backImage__9cz9b {
    position: absolute;
    top: 0;
    left: 0;
    width: max(40vw, 150px);
    height: max(40vw, 150px);
    object-fit: cover;
    -webkit-transform: scale(1);
            transform: scale(1);
    background: var(--productImage);
    transition: all 0.2s cubic-bezier(0.61, 1, 0.88, 1),
      -webkit-transform 0.4s cubic-bezier(0.61, 1, 0.88, 1);
    transition: all 0.2s cubic-bezier(0.61, 1, 0.88, 1),
      transform 0.4s cubic-bezier(0.61, 1, 0.88, 1);
    transition: all 0.2s cubic-bezier(0.61, 1, 0.88, 1),
      transform 0.4s cubic-bezier(0.61, 1, 0.88, 1),
      -webkit-transform 0.4s cubic-bezier(0.61, 1, 0.88, 1);
    z-index: -1;
    opacity: 0;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(248, 246, 253);
  align-items: center;
  margin-left: auto;

}

body,
input,
textarea,
button {
  font-family: "Inter", sans-serif;
}

.app {
  width: 400px;
  margin: 0 auto;
  height: 100vh;

}

/* Contact.js */
.form {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  margin-top: 100px;
  margin-left: 550px;
  
}


.form > h1 {
  margin-bottom: 30px;
}

.form > input,
textarea {
  padding: 20px;
  border-radius: 3px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137); */
  margin-bottom: 20px;
  border: 1px solid lightgray;
  /* border: none; */
  background: #fff;
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none;
  align-items: center;
}

.form > input:focus,
textarea:focus {
  border: 1px solid orange;
}

.form > textarea {
  height: 150px;
  max-width: 400px;
  min-height: 100px;
}

.form > label {
  padding-bottom: 10px;
  color: rgb(0, 0, 32);
  font-weight: bold;
}

.form > button {
  padding: 20px;
  border: none;
  background-color: orange;
  font-weight: 500;
  font-size: 20px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-top: 10px;
}

.form > button:hover {
  background-color: rgb(223, 145, 1);
}
@media screen and (max-width: 1000px) {
  .form, input[type=submit] {
    width: 100%;
    margin-top: 0;
    margin-left: 50px;
    margin-top: 100px;
  }
}
.Footer_container__2fXSk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  top: 12%;
  position: relative;
  z-index: 5;
  cursor: default;
  height: 400px;
  background-color: #222222;
  margin-right: 700px;
  align-self: left;
}

.Footer_logo__1rjTa,
.Footer_shop__DdB4r,
.Footer_btn__2jAd0,
.Footer_cartLengthBlack__2Vajh {
  color: var(--primary);
}

.Footer_logoBlack__yndQc,
.Footer_shopBlack__2LuNX,
.Footer_btnBlack__2kg7o,
.Footer_cartLength__3Fy6k {
  color: var(--shopPrimary);
}

.Footer_logo__1rjTa {
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Montserrat Medium";
  font-weight: 500;
  letter-spacing: 5px;
}

.Footer_logoBlack__yndQc {
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Montserrat Medium";
  font-weight: 500;
  letter-spacing: 5px;
}

.Footer_nav__1uVmo {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Footer_shop__DdB4r {
  text-decoration: none;
  font-size: 0.9rem;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
}

.Footer_shopBlack__2LuNX {
  text-decoration: none;
  font-size: 0.9rem;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
}

.Footer_btn__2jAd0 {
  background: none;
  border: none;
  font-size: 0.9rem;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer_btnBlack__2kg7o {
  background: none;
  border: none;
  font-size: 0.9rem;
  cursor: pointer;
  outline: none;
}

.Footer_svgSearch__3-Gjc {
  width: 26px;
  height: 26px;
}

.Footer_cartBtnContainer__tGMNJ {
  position: relative;
  display: flex;
  align-items: center;
  height: 400px;
}

.Footer_cartLength__3Fy6k {
  position: relative;
  width: 29px;
  height: 29px;
  left: calc(-100% - 7px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: var(--shopSecondary);
  font-size: 0.9rem;
  font-family: "Montserrat Bold";
  font-weight: 700;
  height: 400px;
}

.Footer_cartLengthBlack__2Vajh {
  position: relative;
  width: 29px;
  height: 29px;
  left: calc(-100% - 7px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: var(--shopPrimary);
  font-size: 0.9rem;
  font-family: "Montserrat Bold";
  font-weight: 700;
  height: 400px;
}

.Footer_svgArrow__2cSzK {
  position: relative;
  left: calc(100% - 2px);
  color: var(--shopSecondary);
  height: 400px;
}

.Footer_svgArrowBlack__2Pi2Q {
  position: relative;
  left: calc(100% - 2px);
  color: var(--shopPrimary);
  height: 400px;
}

.Footer_svgCart__3w5um {
  width: 29px;
  height: 29px;
}

@media (max-width: 780px) {
  .Footer_container__2fXSk {
    flex-direction: column;
    height: 100px;
    top: 5%;
  }

  .Footer_nav__1uVmo {
    width: 90%;
    justify-content: space-around;
    position: relative;
    height: 400px;
    margin-right: 700px;
  }
}
.Footer_pog__1pJEo {
  color: rgb(124, 124, 124);
  text-decoration: none;
  align-self: center;
  margin-right: 50px;
  margin-left: 200px;
}
.Footer_media__35eDl {
  color: gray;
  margin-bottom: 20px;
  font-size: 18px;
  text-decoration: none;
  margin-left: 200px;
}
.Footer_media__35eDl:hover {
  color: orange;
  transition: 200ms ease-in;
}
.Footer_loo__1BugL {
  position: relative;
  font-size: 20px;
  color: #928f8f;
  z-index: -1;
  margin-right: 300px;
  margin-left: 100px;
}
.Footer_social__16P8X {
  left: 20px;
  position: absolute;
  right: 0;
  top: 25%;
  z-index: 10;
  color: black;
  font-size: 20px;
}
.Footer_right__lawUV {
  margin-top: 300px;
  margin-left: -900px;
}

.word {
  margin-right: 300px;
  margin-left: 300px;
  margin-top: 300px;
}
@media screen and (max-width: 600px) {
  .word {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 300px;
  }
}

