.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000dd;
  z-index: 9;
}

.cart {
  position: fixed;
  top: 0;
  right: 0;
  width: 33.33333%;
  height: 100%;
  background: #f8f5f2;
  z-index: 10;
  overflow-y: scroll;
}

.cart::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 70%;
  height: 100%;
  margin: 0 auto;
  cursor: default;
}

.close {
  position: absolute;
  top: 6.5%;
  right: 14%;
  padding: 2.5%;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
}

.svgClose {
  width: 19px;
  height: 19px;
}

.cartContentWrapper {
  width: 100%;
}

.title {
  padding: 113px 0 0;
  font-size: 32px;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  line-height: 32px;
}

.cartList {
  padding: 16px 0;
}

.totalPrice {
  margin: 25px 0 0;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  font-size: 0.9rem;
}

.subtitle {
  margin: 40px 0 0;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  font-size: 12px;
}

.svgCart {
  fill: #e0ddda;
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 75px);
  width: 150px;
  height: 150px;
}

@media (max-width: 780px) {
  .cart {
    width: 100%;
  }
}
