.container {
  min-height: 100vh;
  background: var(--shopSecondary);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--shopPrimary);
  cursor: default;
}

.main {
  flex: 1;
  min-height: 100vh;
  padding: 0 25px 0 75px;
  margin-right: 25px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.cursor {
  position: absolute;
  display: none;
  width: 80px;
  height: 80px;
  background: #231f20;
  z-index: 15;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: top 0.1s ease-out, left 0.1s ease-out;
  color: var(--shopSecondary);
  justify-content: center;
  align-items: center;
}

.interactionArea {
  position: absolute;
  width: 90%;
  height: 60%;
}

.leftClickArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
}

.rightClickArea {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  z-index: 1;
}

.leftClickArea:hover {
  cursor: none;
}

.rightClickArea:hover {
  cursor: none;
}

.bgStyle {
  background: #efece9;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
}

.productName {
  z-index: 2;
  position: absolute;
  top: 30%;
  left: 10%;
  width: 20%;
  font-size: 64px;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  line-height: 64px;
  pointer-events: none;
}

.productImage {
  z-index: 1;
  -webkit-user-drag: none;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.1s;
}

.galleryCounter {
  z-index: 1;
  position: absolute;
  bottom: 5.5%;
  right: 7.5%;
  font-family: "Montserrat Medium";
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 0.9rem;
  pointer-events: none;
}

.galleryCurrentIndex {
  font-family: "Montserrat Bold";
  font-weight: 700;
}

.side {
  width: 20vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 50px 0 25px;
}

.squares {
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 30vh 0 0;
}

.square {
  text-align: center;
  width: 45px;
  line-height: 45px;
  text-transform: uppercase;
  font-family: "Montserrat Bold";
  font-weight: 700;
  font-size: 0.9rem;
}

.productPrice {
  font-size: 1.3rem;
  font-family: "Montserrat Medium";
  font-weight: 500;
  letter-spacing: 2px;
  margin: 10vh 0 0;
}

.buyButtons {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20vh 0 0;
}

.addToBag {
  background: #c9c1b1;
  border: none;
  outline: none;
  color: var(--shopPrimary);
  font-size: 0.9rem;
  cursor: pointer;
  text-transform: uppercase;
  height: 75px;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  letter-spacing: 3px;
  position: relative;
}

.addToBag div {
  height: 1rem;
  overflow: hidden;
  position: relative;
}

.addToBag div :nth-child(1) {
  position: absolute;
  top: calc(50% - (0.9rem / 2));
  left: 0%;
  width: 100%;
  height: 0.9rem;
  overflow: hidden;
  transition: top 0.2s ease-in;
}

.addToBag div :nth-child(2) {
  position: absolute;
  top: 100%;
  left: 0%;
  width: 100%;
  height: 0.9rem;
  overflow: hidden;
  transition: top 0.2s ease-in;
}

.addToBag:hover div :nth-child(1) {
  top: calc(0% - 0.9rem);
}

.addToBag:hover div :nth-child(2) {
  top: calc(50% - (0.9rem / 2));
}

.buyNow {
  margin: 20px 0 0;
  background: #89734c;
  border: none;
  outline: none;
  color: var(--shopSecondary);
  font-size: 0.9rem;
  cursor: pointer;
  text-transform: uppercase;
  height: 75px;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  letter-spacing: 3px;
}

@media (max-width: 780px) {
  .container {
    flex-direction: column;
  }

  .main {
    flex: 1;
    min-height: 100vh;
    padding: 150px 5vw 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .productName {
    z-index: 2;
    position: relative;
    top: 0;
    left: 0;
    width: 80%;
    font-size: 32px;
    font-family: "Montserrat SemiBold";
    font-weight: 600;
    line-height: 32px;
    pointer-events: none;
  }

  .productImage {
    max-width: 80vw;
    height: auto;
    z-index: 1;
    -webkit-user-drag: none;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.1s;
  }

  .side {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 50px 0;
  }

  .squares {
    margin: 50px 0 0;
  }

  .productPrice {
    margin: 50px 0 0;
  }

  .buyButtons {
    margin: 50px 0 100px;
  }
}
