.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  top: 12%;
  position: relative;
  z-index: 5;
  cursor: default;
  height: 400px;
  background-color: #222222;
  margin-right: 700px;
  align-self: left;
}

.logo,
.shop,
.btn,
.cartLengthBlack {
  color: var(--primary);
}

.logoBlack,
.shopBlack,
.btnBlack,
.cartLength {
  color: var(--shopPrimary);
}

.logo {
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Montserrat Medium";
  font-weight: 500;
  letter-spacing: 5px;
}

.logoBlack {
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Montserrat Medium";
  font-weight: 500;
  letter-spacing: 5px;
}

.nav {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shop {
  text-decoration: none;
  font-size: 0.9rem;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
}

.shopBlack {
  text-decoration: none;
  font-size: 0.9rem;
  font-family: "Montserrat SemiBold";
  font-weight: 600;
}

.btn {
  background: none;
  border: none;
  font-size: 0.9rem;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnBlack {
  background: none;
  border: none;
  font-size: 0.9rem;
  cursor: pointer;
  outline: none;
}

.svgSearch {
  width: 26px;
  height: 26px;
}

.cartBtnContainer {
  position: relative;
  display: flex;
  align-items: center;
  height: 400px;
}

.cartLength {
  position: relative;
  width: 29px;
  height: 29px;
  left: calc(-100% - 7px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: var(--shopSecondary);
  font-size: 0.9rem;
  font-family: "Montserrat Bold";
  font-weight: 700;
  height: 400px;
}

.cartLengthBlack {
  position: relative;
  width: 29px;
  height: 29px;
  left: calc(-100% - 7px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: var(--shopPrimary);
  font-size: 0.9rem;
  font-family: "Montserrat Bold";
  font-weight: 700;
  height: 400px;
}

.svgArrow {
  position: relative;
  left: calc(100% - 2px);
  color: var(--shopSecondary);
  height: 400px;
}

.svgArrowBlack {
  position: relative;
  left: calc(100% - 2px);
  color: var(--shopPrimary);
  height: 400px;
}

.svgCart {
  width: 29px;
  height: 29px;
}

@media (max-width: 780px) {
  .container {
    flex-direction: column;
    height: 100px;
    top: 5%;
  }

  .nav {
    width: 90%;
    justify-content: space-around;
    position: relative;
    height: 400px;
    margin-right: 700px;
  }
}
.pog {
  color: rgb(124, 124, 124);
  text-decoration: none;
  align-self: center;
  margin-right: 50px;
  margin-left: 200px;
}
.media {
  color: gray;
  margin-bottom: 20px;
  font-size: 18px;
  text-decoration: none;
  margin-left: 200px;
}
.media:hover {
  color: orange;
  transition: 200ms ease-in;
}
.loo {
  position: relative;
  font-size: 20px;
  color: #928f8f;
  z-index: -1;
  margin-right: 300px;
  margin-left: 100px;
}
.social {
  left: 20px;
  position: absolute;
  right: 0;
  top: 25%;
  z-index: 10;
  color: black;
  font-size: 20px;
}
.right {
  margin-top: 300px;
  margin-left: -900px;
}
