.word {
  margin-right: 300px;
  margin-left: 300px;
  margin-top: 300px;
}
@media screen and (max-width: 600px) {
  .word {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 300px;
  }
}
